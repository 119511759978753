import * as React from 'react';
import { Link } from 'react-router-dom';

import LoginPage from '@common/react/components/Pages/Login/Login';

import { User } from '@app/objects/User';

import '@app/scss/pages/login.scss';

const Login: React.FC = () => {
	return (
		<LoginPage<User>
			request="authPortal"
			containerClassName="enter-page login-page portal-form-center open-page"
			withFieldTitle
			buttons={<Link to="/registration" className="btn btn-default register-link">Register</Link>}
			authByCodeRequest="authByCode"
		/>
	);
};

export default Login;
